<template>
    <ul class="flex items-center h-20 space-x-5 text-base">
        <li>
            <a
                id="BulkSearchHyperLink"
                class="bom-link"
                :href="localePath('/bom/import')"
                :title="$t('Layout.BulkSearchLinkText')">
                <b v-html="$t('Layout.BulkSearchLinkText')"></b>
            </a>
        </li>
        <li v-if="faqEnabled">
            <a id="FAQLink" href="/faq" :title="$t('Layout.FAQLink')" class="link-white">
                {{ $t("Layout.FAQLink") }}
            </a>
        </li>
        <li>
            <DropdownMenu v-if="isAuthenticated">
                <HeaderMenuTrigger>
                    {{ $t("Layout.MyAccountLink") }}
                </HeaderMenuTrigger>
                <DropdownMenuContent>
                    <DropdownMenuItem v-for="link in accountMenuLinks" :key="link.to">
                        <NuxtLink class="link-gray justify-center" :prefetch="false" v-bind="link">
                            {{ link.title }}
                        </NuxtLink>
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
            <NuxtLink
                v-else
                id="sign-in-button"
                external
                :href="localePath('/sign-in')"
                class="link-white"
                :title="$t('SignIn.LoginButtonText')">
                {{ $t("SignIn.LoginButtonText") }}
            </NuxtLink>
        </li>
    </ul>
</template>
<script setup lang="ts">
const localePath = useLangPath();
const isAuthenticated = useStateIsAuthenticated();
const globalConfig = useStateGlobalConfig();
const accountMenuLinks = useAccountLinks();

const faqEnabled = computed(() => globalConfig.value.Features?.FAQ_STATIC_SITE || false);
</script>
