type AccountLinkItem = { to: string; title: string; class?: string; external?: boolean };

export default function () {
    const { t } = useI18n();
    const localePath = useLangPath();
    const isUserInRole = useIsUserInRole();

    const isAdmin = computed(
        () =>
            isUserInRole(Role.Admin) ||
            isUserInRole(Role.ReportingAdmin) ||
            isUserInRole(Role.MemberAdmin) ||
            isUserInRole(Role.TechnicalAdmin) ||
            isUserInRole(Role.ContentAdmin)
    );

    return computed<AccountLinkItem[]>(() => {
        const links: AccountLinkItem[] = [{ to: localePath("/my-account"), title: t("Layout.AccountDetails") }];

        if (isAdmin.value) links.push({ to: "/administration", title: t("Layout.LinkAdministration"), external: true });

        if (isUserInRole(Role.Distributor))
            links.push({ to: "/distributor/landing", title: t("Layout.LinkDistributorLanding"), external: true });

        if (isUserInRole(Role.Manufacturer))
            links.push({ to: "/manufacturer/landing", title: t("Layout.LinkManufacturerLanding"), external: true });

        if (isUserInRole(Role.LeadTimeMonthly) || isUserInRole(Role.LeadTimeWeekly))
            links.push({ to: localePath("/lead-time-reporting"), title: t("Layout.LinkReporting"), external: true });

        links.push({
            to: localePath("/logout"),
            title: t("Layout.LinkSignOut"),
            class: "font-bold",
        });

        return links;
    });
}
